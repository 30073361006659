@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/* Misc. Rules */

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.notimplemented {
  display: none;
}

/* Nav Bar Rules */
nav {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  top: 0px;
  position: sticky;
  z-index: 100;
}

nav br {
  display: none;
}

#logo {
  width: 130px;
  height: 130px;
}

#logo img {
  height: 100%;
  width: auto;
}

#navmenu {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

/* Hamburger menu rules */
#burger {
  margin-right: 2rem;
  width: 10.5rem;
  height: 2.55rem;
}

#burger span {
  display: block;
  background-color: #000;
  width: 3rem;
  height: 0.5rem;
  margin-bottom: 0.35rem;
  margin-left: 7.5rem;
}

/* Create animation for hamburger */
#firstline,
#secondline,
#thirdline {
  transition: all 0.5s ease;
}

.crossed #firstline {
  transform: rotate(45deg) translate(9px, 10px);
}

.crossed #secondline {
  background: transparent;
}

.crossed #thirdline {
  transform: rotate(-45deg) translate(9px, -10px);
}

.dropdown {
  background-color: #26b9d9;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  position: relative;
  padding: 1rem;
  padding-bottom: 0;
  width: 100%;
  right: 0rem;
  list-style: none;
  border-radius: 10px;
}

.dropdown li {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  width: 100%;
}

.dropdown li a,
.hidden li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.dropdown li a:hover {
  color: #fff;
}

/* Hide menu when hamburger not clicked */
.hidden {
  display: none;
}

/* Banner & Action Button */
#banner {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

#titleaction {
  position: absolute;
  z-index: 2;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

#titleaction h1 {
  font-size: 3rem;
  font-family: "Arvo", sans-serif;
  margin-bottom: 1rem;
}

#titleaction h2 {
  width: 100%;
  margin: 1.5rem auto;
}

#titleaction .button {
  width: 10rem;
  height: 4rem;
  margin: 0 auto;
}

#titleaction .button a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-family: "Arvo", sans-serif;
  color: #000;
  background-color: #26b9d9;
  border-radius: 10px;
  transition: 0.5s;
}

#titleaction .button a:hover {
  background-color: #fff;
}

/* Image Carousel Rules */
#imagecarousel {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
  margin: 0;
  padding: 0;
}

#imagecarousel li {
  list-style: none;
}

#imagecarousel li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 28s linear infinite 0s;
}

#imagecarousel li:nth-child(1) span {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./images/forg3d-jeepplug.jpeg");
}

#imagecarousel li:nth-child(2) span {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./images/forg3d-keypad.jpeg");
  animation-delay: 7s;
}

#imagecarousel li:nth-child(3) span {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./images/forg3d-cupholder.jpeg");
  animation-delay: 14s;
}

#imagecarousel li:nth-child(4) span {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./images/forg3d-usboutlet.jpeg");
  animation-delay: 21s;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  10% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* General layout for various sections */
#featureditems,
#services,
#aboutus {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.subheader {
  text-align: center;
  width: 100%;
  font-size: 2.25rem;
  text-transform: uppercase;
  font-family: "Arvo", sans-serif;
}

/* Featured Item section */
.product {
  width: 60%;
  margin-bottom: 2rem;
}

.product a {
  width: 100%;
  text-decoration: none;
  color: #000;
}

.product a img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 10px;
}

.product h4,
.service h4 {
  text-align: center;
  font-size: 1.25rem;
  margin: 0;
  margin-top: 0.5rem;
}

.productprice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.productprice p,
.service p {
  padding-left: 2rem;
  padding-right: 2rem;
}

.prevprice {
  text-decoration: line-through;
}

.currentprice {
  font-weight: bold;
}

.product .button {
  width: 50%;
  margin: 1rem auto;
}

.product .button a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
}

.product .button a:hover {
  background-color: #26b9d9;
}

/* Services section */
#services {
  background-color: #26b9d9;
  color: #fff;
}

#services .service {
  width: 60%;
  text-align: center;
  margin-bottom: 2rem;
}

.service img {
  width: 50%;
  height: auto;
}

/* Helpful Buttons */
#fbdownload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #26b9d9;
}

#fbdownload div {
  width: 100%;
  padding-bottom: 3rem;
}

#fbdownload .button {
  width: 17rem;
  height: 6rem;
  margin: 0 auto;
  text-align: center;
}

#fbdownload .button a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-family: "Arvo", sans-serif;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

#fbdownload .button a:hover {
  background-color: #fff;
  color: #26b9d9;
}

/* About Us section */
#aboutus {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#aboutus div {
  width: 90%;
}

#leftside {
  text-align: center;
}

#leftside h3 {
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Arvo", sans-serif;
}

#rightside {
  display: none;
}

/* Contact section */
#contact {
  /* width: 100%; */
  background-color: #26b9d9;
  color: #fff;
  text-align: center;
  padding: 2rem;
}

#contact .button {
  width: 10rem;
  height: 4rem;
  margin: 2rem auto;
}

#contact .button a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-family: "Arvo", sans-serif;
  color: #26b9d9;
  background-color: #000;
  border-radius: 10px;
  transition: 0.5s;
}

#contact .button a:hover {
  background-color: #fff;
}

/* Game Instructions Page */
#gamebanner {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./images/pennydrop-splash.jpeg");
  background-color: rgba(0, 0, 0, 0.173);
  background-blend-mode: darken;
  background-size: cover;
}

#gameinstructions {
  width: 95%;
  margin: 1rem auto;
  font-size: 1.1rem;
  font-family: "Arvo", sans-serif;
}

#gameinstructions .gamenote {
  font-size: .9rem;
  font-style: italic;
}

.instructionlist li {
  padding-bottom: 1rem;
}

#buyinstructions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 2rem;
}

#buyinstructions div {
  width: 100%;
  padding-bottom: 3rem;
}

#buyinstructions .button {
  width: 17rem;
  height: 6rem;
  margin: 0 auto;
  text-align: center;
}

#buyinstructions .button a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-family: "Arvo", sans-serif;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

#buyinstructions .button a:hover {
  background-color: #26b9d9;
  color: #fff;
}

/* Footer rules */
footer {
  text-align: center;
  padding: 1.5rem;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  nav br {
    display: block;
  }
  
  #navmenu {
    width: 85%;
  }

  #burger {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  #burger span {
    display: none;
  }

  .dropdown,
  .hidden {
    display: flex;
    justify-content: center;
    padding: 0;
    border-radius: 0;
    background-color: #fff;
    border-radius: 0px;
  }

  .hidden {
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    right: 0rem;
    list-style: none;
  }

  .dropdown li,
  .hidden li {
    margin: 0;
    width: 16.5%;
    text-align: center;
  }

  .hidden li {
    font-size: 1.25rem;
  }

  .dropdown li a:hover,
  .hidden li a:hover {
    color: #26b9d9;
  }

  #banner, #gamebanner {
    height: 600px;
  }

  #titleaction h1 {
    font-size: 4.5rem;
  }

  #titleaction h2 {
    width: 75%;
  }

  .product {
    width: 30%;
  }

  .product a img {
    height: 350px;
  }

  #services .service {
    width: 30%;
    min-height: 400px;
  }

  #fbdownload div {
    width: 32%;
  }

  #fbdownload .button {
    width: 17rem;
  }

  #aboutus div {
    width: 45%;
  }

  #leftside {
    text-align: left;
  }

  #rightside {
    display: block;
    text-align: center;
  }

  #rightside img {
    width: 75%;
    height: auto;
  }

  #gameinstructions {
    font-size: 1.4rem;
  }

  #gameinstructions .gamenote {
    font-size: 1.2rem;
  }

  #buyinstructions div {
    width: 50%;
  }
}
